import React from 'react'

import Template from 'components/GeneralPlans/Template'
import JoinLeftSide from 'components/GeneralPlans/left-sides/JoinLeftSide'
import FreemiumSignUpForm from 'components/freemium-sign-up/FreemiumSignUpForm'

export default function FreemiumSignUp() {
  return (
    <Template>
      <JoinLeftSide
        title='Your selected package'
        subTitle='And preferable billing method'
      />
      <FreemiumSignUpForm />
    </Template>
  )
}
