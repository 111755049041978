import axios from 'axios'

export default ({ url = '', method = 'get', params = {}, data = {} }) => {
  return axios({
    url,
    method,
    params,
    data
  })
}
