import React, { useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import { useLocation, useHistory } from 'react-router-dom'
import { usePlanData, useCouponData, useSetCouponData } from 'hooks/useGlobalState'
import Details from './Details'
import { toInteger } from 'lodash'

export default React.memo(function PlanPreview() {
  const location = useLocation()
  const history = useHistory()
  const setCouponData = useSetCouponData((prev, coupon) => ({ ...prev, coupon }), [])

  const queryParams = new URLSearchParams(location.search)
  const initialBilled = queryParams.get('billed') || 'monthly'

  const [isAnnual, setIsAnnual] = useState(initialBilled === 'annual')

  const planData = usePlanData()
  const couponData = useCouponData()

  if (!planData) return null

  const price = get(planData, 'price', '')
  const billedLabel = get(planData, 'billed_label', '')
  const oneTimeFee = get(planData, 'one_time_fee', '')
  const show = toInteger(oneTimeFee) > 0;

  const displayBilledLabel = isAnnual ? 'annually' : 'monthly';

  const discountedPrice = get(couponData, 'coupon.price', '')
  const discountDescription = get(couponData, 'coupon.description', '')

  // const handlePlanToggle = (isAnnualPlan) => {
  //   setIsAnnual(isAnnualPlan)
  //   const newBilledValue = isAnnualPlan ? 'annual' : 'monthly'
  //   queryParams.set('billed', newBilledValue)
  //   history.replace({ search: queryParams.toString() })
  //   setCouponData(null)
  // }

  return (
    <StyledWrapper>
      {/* <ToggleButtonWrapper>
        <Button active={isAnnual} onClick={() => handlePlanToggle(true)}>Annually</Button>
        <Button active={!isAnnual} onClick={() => handlePlanToggle(false)}>Monthly</Button>
      </ToggleButtonWrapper> */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
        {discountedPrice ? (
          <>
            <p className="price">
              <span className="old-price">{price}</span> <span className="new-price">${discountedPrice}</span>
            </p>
            <p className="discount-description">{discountDescription}</p>
          </>
        ) : (
          <p className="price">{price}</p>
        )}
        <p className="description">Billed {displayBilledLabel}</p>
      </div>
      <div className="details-wrapper">
        <div className="list-container">
          {show && <p>${oneTimeFee} One time fee</p>}
          <Details />
        </div>
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: left;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--White, #FFF);

  .price {
    color: #1E1B4B;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;

    .old-price {
      text-decoration: line-through;
    }

    .new-price {
      color: #067647;
      margin-left: 8px;
    }
  }
  
  .description {
    color: rgba(30, 27, 75, 0.80);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .discount-description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #067647;
  }

  .details-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    width: 100%;
  }

  .list-container {
    flex-grow: 1;
  }
`

const ToggleButtonWrapper = styled.div`
  display: flex;
  justify-content: center; 
  margin-bottom: 16px;
  width: 100%;  
`

const Button = styled.button`
  background: ${({ active }) => (active ? '#F38744' : 'transparent')};
  color: ${({ active }) => (active ? '#FFFFFF' : '#D97706')};
  border: none;
  border-radius: 10px; 
  padding: 7px 12px; 
  cursor: pointer;
  font-size: 16px;
`