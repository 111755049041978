import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { PayPalButton } from 'react-paypal-button-v2'
import styled from 'styled-components'
import get from 'lodash/get'

import { THANK_YOU } from 'config/routes'

import { usePlanData, useCouponCode, useIsCouponCodeValid } from 'hooks/useGlobalState'

import request from 'utils/request'
import createNotification from 'utils/createNotification'

export default React.memo(function PayPalForm () {
  const { search } = useLocation()
  const [error, setError] = React.useState(null)
  const history = useHistory()
  const planId = usePlanData(current => get(current, 'id', '').toString())
  const paymentId = usePlanData(current => get(current, 'payment_id'))
  const billedType = usePlanData(current => get(current, 'billed_type'))
  const priceAmount = usePlanData(current => get(current, 'price_amount'))
  const couponCode = useCouponCode()
  const isCouponCodeValid = useIsCouponCodeValid()

  const createSubscription = React.useCallback((data, actions) => {
    return actions.subscription.create({
      plan_id: paymentId
    })
  }, [paymentId])

  const onApprove = React.useCallback((data) => {
    const requestBody = {
      plan: planId,
      subscription: data.subscriptionID || null,
      order: data.orderID
    }
    if (isCouponCodeValid) requestBody.coupon = couponCode
    createNotification({
      message: 'Registering new account...',
      type: 'info'
    })
    request({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/patients/subscribe`,
      data: requestBody
    })
      .then(() => history.push(`/${THANK_YOU}${search}`))
      .catch((error) => {
        console.log(error)
        createNotification({
          message: get(error, 'response.data.message', ''),
          type: 'error'
        })
        setError(get(error, 'response.data.message', ''))
      })
  }, [planId, search, history, couponCode, isCouponCodeValid])

  const payPalButtonProps = React.useMemo(() => {
    if (billedType === 'one-time') {
      return {
        amount: priceAmount,
        options: {
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID
        },
        onApprove
      }
    }
    return {
      options: {
        vault: true,
        intent: 'subscription',
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID
      },
      createSubscription,
      onApprove
    }
  }, [billedType, priceAmount, onApprove, createSubscription])

  if (!paymentId) return null

  return (
    <div className='g-mt-15'>
      <PayPalButton
        {...payPalButtonProps}
      />
      {error && (
        <>
          <StyledError>{error}</StyledError>
          <StyledLink href='mailto:support@cibahealth.com'>support@cibahealth.com</StyledLink>
        </>
      )}
    </div>
  )
})

const StyledError = styled.div`
  color: #f86060;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`

const StyledLink = styled.a`
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  font-size: 1.2rem;
  color: #3242c6;
`
