import React from 'react'

import Routes from 'Routes'

import { Amplify } from "aws-amplify";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const USERPOOL_ID = process.env.REACT_APP_USERPOOL_ID;
const AWS_SOCIAL_LOGIN_DOMAIN = process.env.REACT_APP_AWS_SOCIAL_LOGIN_DOMAIN;

Amplify.configure({
  region: "us-east-2",
  userPoolId: `${USERPOOL_ID}`,
  userPoolWebClientId: `${CLIENT_ID}`,
  authenticationFlowType: "USER_PASSWORD_AUTH",
  oauth: {
    domain: AWS_SOCIAL_LOGIN_DOMAIN,
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: `${window.location.origin}/login`,
    redirectSignOut: `${window.location.origin}/logout`,
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
});

export default function App () {
  return (
    <Routes />
  )
}
