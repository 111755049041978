import React from 'react'

import Template from 'components/ThrivePlan/Template'
import ThriveThankYouForm from 'components/ThriveThankYouForm'
import ThriveJoinLeftSide from 'components/ThrivePlan/left-sides/ThriveJoinLeftSide'

export default function ThriveThankYou() {
  return (
    <Template>
      <ThriveJoinLeftSide />
      <ThriveThankYouForm />
    </Template>
  )
}

