import React from 'react'

import Template from 'components/GeneralPlans/Template'
import JoinLeftSide from 'components/GeneralPlans/left-sides/JoinLeftSide'
import CorporateSignUpForm from 'components/corporate-sign-up/CorporateSignUpForm'

export default function CorporateSignUp() {
  return (
    <Template>
      <JoinLeftSide
        title='Your selected package'
        subTitle='And preferred billing method'
      />
      <CorporateSignUpForm />
    </Template>
  )
}
