import React from 'react'

import Template from 'components/GeneralPlans/Template'
import JoinLeftSide from 'components/GeneralPlans/left-sides/JoinLeftSide'
import VerificationContent from 'components/email-varification/VerificationContent'

export default function EmailVarification() {
  return (
    <Template>
      <JoinLeftSide
        title='Your selected package'
        subTitle='And preferred billing method'
      />
      <VerificationContent />
    </Template>
  )
}
