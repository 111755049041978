import React from 'react'
import styled from 'styled-components'

import signUp from 'assets/sign-up.jpg'
import signUpTablet from 'assets/sign-up-tablet.jpg'
import signUpMobile from 'assets/sign-up-mobile.jpg'

import LeftSideHeader from './LeftSideHeader'

export default React.memo(function BillingLeftSide ({
  title = '', subTitle = ''
}) {
  return (
    <StyledWrapper>
      <LeftSideHeader />

      <div className='main-text'>
        <p className='title'>{title}</p>
        <p className='sub-title'>{subTitle}</p>
      </div>

      <div className='mobile-main-text'>
        <p className='title'>{title}</p>
        <p className='sub-title'>{subTitle}</p>
      </div>

      <div />
    </StyledWrapper>
  )
}, () => true)

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  background: url(${signUp}) no-repeat;
  background-position: top right;
  background-size: cover;
  padding: 4% 0 5% 7%;
  @media screen and (max-width: 1000px) {
    position: static;
    padding: 2rem 4% 5rem;
    width: 100%;
    background: url(${signUpTablet}) no-repeat;
    background-position: top center;
    background-size: cover;
  }
  @media screen and (max-width: 480px) {
    background: url(${signUpMobile}) no-repeat;
    background-position: top center;
    background-size: cover;
  }
  .main-text {
    @media screen and (max-width: 1000px) {
      display: none;
    }
    p {
      color: #fff;
      font-weight: 700;
    }
    .title {
      font-size: 3.8rem;
      line-height: 4.8rem;
    }
    .sub-title {
      font-size: 2.4rem;
    }
  }
  .mobile-main-text {
    display: none;
    margin-top: 9rem;
    text-align: center;
    @media screen and (max-width: 1000px) {
      display: block;
    }
    p {
      color: #fff;
    }
    .title {
      font-size: 2.4rem;
      font-weight: 700;
    }
    .sub-title {
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin-top: 0.5rem;
    }
  }
`
