import React from 'react'
import { Router, Route, Switch, useHistory, Redirect } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import {
  BILLING, PROGRAMS, CORPORATE_SIGN_UP, REFERRAL_SIGN_UP, THANK_YOU, THRIVE_THANK_YOU, EMAIL_VERIFICATION,
  CORPORATE_VERIFY, EMAIL_VERIFIED
} from 'config/routes'

import CorporateSignUp from 'containers/CorporateSignUp'
import Billing from 'containers/Billing'
import Programs from 'containers/Programs'
import ThankYou from 'containers/ThankYou'
import ThriveThankYou from 'containers/ThriveThankYou'
import EmailVarification from 'containers/EmailVarification'
import CorporateVerify from 'containers/CorporateVerify'
import FreemiumSignUp from 'containers/FreemiumSignUp'
import EmailVerified from 'containers/EmailVerified'

import history from 'utils/createdHistory'

const ScrollToTop = React.memo(function ScrollToTop() {
  const history = useHistory()
  React.useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo(0, 0))
    return () => unlisten()
    // eslint-disable-next-line
  }, [])
  return null
})

export default function Routes() {
  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        <Route path={`/${BILLING}`} component={Billing} />
        <Route path={`/${PROGRAMS}`} component={Programs} />
        <Route path={`/${THANK_YOU}`} component={ThankYou} />
        <Route path={`/${THRIVE_THANK_YOU}`} component={ThriveThankYou} />
        <Route path={`/${CORPORATE_SIGN_UP}`} component={CorporateSignUp} />
        <Route path={`/${REFERRAL_SIGN_UP}`} component={FreemiumSignUp} />
        <Route path={`/${EMAIL_VERIFICATION}`} component={EmailVarification} />
        <Route path={`/${CORPORATE_VERIFY}`} component={CorporateVerify} />
        <Route path={`/${EMAIL_VERIFIED}`} component={EmailVerified} />

        <Redirect to={`/${BILLING}`} />
      </Switch>
      <Toaster />
    </Router>
  )
}
