import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import { EMAIL_VERIFIED, CORPORATE_SIGN_UP } from "config/routes";

import createNotification from "utils/createNotification";
import request from "../utils/request";

export default function CorporateVerify() {
  const history = useHistory();
  const { search } = useLocation();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const name = searchParams.get("user_name");
    const code = searchParams.get("code");

    if (name && code) {
      request({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/patients/verify-email`,
        data: {
          username: name,
          code: code
        }
      })
        .then(() => history.push(`/${EMAIL_VERIFIED}`))

        .catch(() => {
          window.location.replace(`${process.env.REACT_APP_ENV_URL}/login?verlinkexp=1`);
        });
    } else {
      window.location.replace(`${process.env.REACT_APP_ENV_URL}/login?verlinkexp=1`);
    }

    // eslint-disable-next-line
  }, [location]);

  return (
    <div>
      <div className="loader-container">
        <div className="lds-ring">
          <div className="lds-ring-back" />
          <div />
        </div>
      </div>
    </div>
  );
}
