import React from 'react'
import { useLocation } from 'react-router-dom'
import Template from 'components/GeneralPlans/Template'
import ThriveTemplate from 'components/ThrivePlan/Template'
import JoinLeftSide from 'components/GeneralPlans/left-sides/JoinLeftSide'
import ThriveJoinLeftSide from 'components/ThrivePlan/left-sides/ThriveJoinLeftSide'
import BillingForm from 'components/GeneralPlans/BillingForm'
import ThriveBillingForm from 'components/ThrivePlan/BillingForm'

export default function Billing() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const plan = queryParams.get('plan');

  const isThrive = plan === 'thrive-plan';
  return (
    <>
      {isThrive
        ?
        <ThriveTemplate>
          <ThriveJoinLeftSide />
          <ThriveBillingForm />
        </ThriveTemplate>
        :
        <Template>
          <JoinLeftSide
            title='Join us'
            subTitle={'Create your Ciba account'}
          />
          <BillingForm />
        </Template>}
    </>
  )
}
