import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { usePlanData } from 'hooks/useGlobalState'

import celebrate from 'assets/celebrate.svg'
import FormTitle from 'common/FormTitle'
import CustomButton from 'common/CustomButton'

export default React.memo(function ThankYouForm () {
  const planData = usePlanData()

  const planTitle = get(planData, 'title', '')

  const redirectHandler = React.useCallback(() => {
    window.location.href = `${process.env.REACT_APP_ENV_URL}/login`
  }, [])

  return (
    <StyledWrapper>
      <img
        className='icon'
        src={celebrate}
        alt=''
      />

      <FormTitle
        title='Thank you!'
        subTitle={`Your password has been sent to your email.`}
      />
      <CustomButton
        className='g-mt-35'
        clickHandler={redirectHandler}
      >
        To the dashboard
      </CustomButton>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  margin-left: 63.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 36.5%;
  padding-left: 4.5%;
  @media screen and (max-width: 1000px) {
    margin-left: 0;
    width: 100%;
    padding: 4rem 4% 8rem;
    align-items: center;
  }
  .icon {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: 2.5rem;
  }
  .sub-title {
    font-size: 1.5rem;
  }
`
