import React from 'react'

import Template from 'components/GeneralPlans/Template'
import WelcomeLeftSide from 'components/GeneralPlans/left-sides/WelcomeLeftSide'
import ThankYouForm from 'components/ThankYouForm'

export default function ThankYou() {
  return (
    <Template>
      <WelcomeLeftSide />
      <ThankYouForm />
    </Template>
  )
}
