import React from 'react'
import styled from 'styled-components'
import thankYou from 'assets/thank-you.jpg'
import thankYouTablet from 'assets/thank-you-tablet.jpg'
import thankYouMobile from 'assets/thank-you-mobile.jpg'

import LeftSideHeader from './LeftSideHeader'

export default React.memo(function WelcomeLeftSide() {
  return (
    <StyledWrapper>
      <LeftSideHeader />

      <div className='main-text'>
        <p className='title'>Welcome</p>
        <p className='sub-title'>to Ciba health</p>
      </div>

      <div />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 63.5%;
  background: url(${thankYou}) no-repeat;
  background-position: top right;
  background-size: cover;
  padding: 4% 0 5% 7%;
  @media screen and (max-width: 1000px) {
    position: static;
    padding: 2rem 4% 5rem;
    width: 100%;
    background: url(${thankYouTablet}) no-repeat;
    background-position: top center;
    background-size: cover;
  }
  @media screen and (max-width: 480px) {
    background: url(${thankYouMobile}) no-repeat;
    background-position: top center;
    background-size: cover;
  }
  .main-text {
    @media screen and (max-width: 1000px) {
      margin-top: 9rem;
      text-align: center;
    }
    p {
      color: #fff;
      font-weight: 700;
    }
    .title {
      font-size: 3.8rem;
      line-height: 4.8rem;
      @media screen and (max-width: 1000px) {
        font-size: 2.4rem;
        line-height: 3rem;
      }
    }
    .sub-title {
      font-size: 2.4rem;
    }
  }
`
